import ReactHtmlParser from 'react-html-parser';

// THE ACTUAL LANGUAGE SETTING IS DETERMINED BY THE SERVER SIDE
// FROM HAPI_MIDDLEMAN WE GET TWO VALUES:
// userCountry: ca || us   & userLanguage en || fr (for ca) || en (for us)
import en_us_translations from './translations/en-us.json';
import en_ca_translations from './translations/en-ca.json';
import fr_ca_translations from './translations/fr-ca.json';

const Polyglot = require('node-polyglot');

//default is 'en_us'
const DEFAULT_COUNTRY = 'us';
const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALE_CODE = 'en-us';

let setCountry = DEFAULT_COUNTRY;
let setLanguage = DEFAULT_LANGUAGE;
export let i18nCustomerLocale = DEFAULT_LOCALE_CODE;

// Callback logic, when the language is changed we want to broadcast that back to whatever registers
const localeChangeCallBackIds = [];
const localeChangeCallBacks = [];
export const registerLocaleChangeListener = (fn, id) => {
  console.log('i18nServer >> registerLocaleChangeListener >> ', fn);
  if (typeof fn === 'function' && id && !localeChangeCallBackIds.includes(id)) {
    localeChangeCallBackIds.push(id);
    return localeChangeCallBacks.push(fn);
  }
  console.log('attempted to register a non-function or an already registered function as a locale update callback');
};

const translations = {
  'en-us': en_us_translations,
  'en-ca': en_ca_translations,
  'fr-ca': fr_ca_translations
};

const _polyGlotInstance = new Polyglot({ locale: i18nCustomerLocale });

// if an html entity (only recognizing one in a set), appears in a string, render as html
const htmlEntityRegex = /<sup>/;

// create a 'translator' object to wrap the PolyGlot 't' method
// this is what is returned to all the consumers via the 'getLocaleTranslator' method
const translator = {
  t: function (key, options) {
    let stringToReturn = '';
    if (options) {
      stringToReturn = _polyGlotInstance.t(key, options);
    } else {
      stringToReturn = _polyGlotInstance.t(key);
    }

    // some entities ought to be parsed as html
    if (stringToReturn && stringToReturn.match(htmlEntityRegex)) {
      return ReactHtmlParser(stringToReturn);
    }

    return stringToReturn;
  },
  getCurrentLanguageCode: function () {
    return _polyGlotInstance.locale();
  },

  i18nCustomerLocale: i18nCustomerLocale
};

// flag to indicate that translations have been set
let LOCALE_PHRASES_SET = false;

export const setPreferredLanguage = ({ userLanguage = setLanguage, userCountry = setCountry } = {}) => {
  // set locale-related variables
  setLanguage = userLanguage.toLowerCase();
  setCountry = userCountry.toLowerCase();
  i18nCustomerLocale = `${setLanguage}-${setCountry}`;
  // set up the dictionary, access the PolyGlot instance directly
  _polyGlotInstance.locale(i18nCustomerLocale);
  const localePhrases = translations[i18nCustomerLocale];

  if (localePhrases === null || localePhrases === 'undefined' || !Object.keys(localePhrases).length) {
    throw new Error('There are no translations in the translations file for the locale: ' + i18nCustomerLocale);
  } else {
    LOCALE_PHRASES_SET = true;
    _polyGlotInstance.extend(localePhrases);
    localeChangeCallBacks.forEach(fn => fn(i18nCustomerLocale));
  }
  return translator;
};

export const getPreferredLanguage = () => i18nCustomerLocale;

export const getLocaleTranslator = () => {
  // ensure that a translations dictionary has been set so that we get the translations
  if (!LOCALE_PHRASES_SET) {
    return setPreferredLanguage();
  }
  return translator;
};

export const findErrorMessage = (error = null) => {
  if (typeof error !== 'object') {
    return error;
  }

  if (typeof error.errorData === 'object') {
    error = error.errorData;
  }

  if (typeof error.error === 'object') {
    error = error.error;
  }

  if (error.errorCode) {
    const key = `errorCodes.${error.errorCode}`;
    const message = translator.t(key);
    if (message && message !== key) {
      return message;
    }
  }

  return error.errorDescription || error.reason || error.message || JSON.stringify(error);
};
